import axios from 'axios'
// import { Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
const _baseUrl = "http://prodh5.shanxinlife.com"
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ?_baseUrl : _baseUrl,//process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : _baseUrl /baseApi
  // baseURL: _baseUrl,
  // withCredentials: true, // 跨域请求时是否发送cookies
  timeout: 60000
})

service.interceptors.request.use(
  (config) => {
    // if (store.getters.token) {
    //   config.headers['token'] = getToken()
    // }
    console.log(config,'fonff')
    if(config.contentType){
      config.headers['Content-Type'] = config.contentType
    }
    return config
  }

)

service.interceptors.response.use(
  (res) => {
    return res.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
